




















































import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import TelaGenerica from '@/components/layout/TelaGenerica.vue'
import DataTableDeCrudPaginado from '@/components/ui/DataTableDeCrudPaginado.vue'
import SeletorDeMarca from '@/components/produto/SeletorDeMarca.vue'
import DialogoDeEdicaoDeMarca from '@/components/produto/DialogoDeEdicaoDeMarca.vue'
import { FindMarcaUseCase } from '@/usecases/produto/marca/FindMarcaUseCase'
import { Marca, Page } from '@/models'
import { CancelToken } from 'axios'
import { Pageable } from '@/models/Pageable'

@Component({
	components: {
		TelaGenerica,
		DialogoDeEdicaoDeMarca,
		DataTableDeCrudPaginado,
		SeletorDeMarca,
	},
})

export default class TelaDeMarcas extends Vue {
	@Ref() dataTablePaginado!: DataTableDeCrudPaginado
	@Ref() dialogoDeEdicao!: DialogoDeEdicaoDeMarca
	
	findMarcaUseCase = new FindMarcaUseCase()
	marcas: Marca[] = []
	busca = ''
	carregando = false
	editando = false
	marca: Marca | null = null
	
	headers = [
		{ text: 'Marca', value: 'nome' },
	]

	abrirDialogoDeEditarMarca(indice: number) {
		this.editando = true
		this.marca = this.marcas[indice]
		
		this.dialogoDeEdicao.mostrar()
	}

	abrirDialogoDeCriarMarca() {
		this.editando = false
		this.marca = {id:"",nome:"", lojas: []}

		this.dialogoDeEdicao.mostrar()
	}

	async buscarPagina(paginavel: Pageable, parametros: {
		busca?: string
	}, cancelToken: CancelToken): Promise<Page<Marca>> {
		const pagina = await this.findMarcaUseCase.findAll({
			...parametros,
			...paginavel,
		}, {
			cancelToken,
		})
		this.marcas = pagina.content
		return pagina
	}

	marcaSalva(marcaSalva) {
		const indice = this.marcas.findIndex(marca => marca.id === marcaSalva.id)

		if (indice === -1) {
			this.marcas.push(marcaSalva)
		} else {
			this.marcas.splice(indice, 1, marcaSalva)
		}
	}

	@Watch('busca')
	novaBusca() {
		this.dataTablePaginado.reiniciar({
			busca: this.busca,
		})
	}
}
